import React, { useState, useContext } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Header from "../../Components/Header/QeteroHeader";
import StatusBoxSkeleton from "../Commons/StatusBoxSkeleton";
import StatusBox from "../Commons/StatusBox";
import {
  FaClipboardCheck,
  FaCheckCircle,
  FaListAlt,
  FaClock,
} from "react-icons/fa";
import { AuthContext } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react"; // Use QRCodeCanvas instead of QRCode
import { useTranslation } from "react-i18next"; // Import useTranslation
import jsPDF from "jspdf"; // Import jsPDF
import LoadingSpinner from "./LoadingSpinner";
import { serviceDataForBH } from "../Services/servicesData";
import { notification } from "antd";
const fetchAppointments = async ({ queryKey }) => {
  const [_, { userId, page, limit, searchTerm, token }] = queryKey;
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/appointments/findAppointmentsByUser?created_by=${userId}&page=${page}&limit=${limit}&searchQuery=${searchTerm}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

// Helper function to format the appointment time
const formatAppointmentTime = (appointmentTime) => {
  const date = new Date(appointmentTime);
  date.setHours(date.getHours() - 6); // Deduct 6 hours

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayName = days[date.getDay()];

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${dayName}, ${year}-${month}-${day} ${hours}:${minutes}`;
};

// Function to generate PDF and trigger download
const generatePdf = (data) => {
  // console.log("THE DATA: ", data);
  const doc = new jsPDF();

  let groupLevel = serviceDataForBH
    .filter((serviceGroup) => serviceGroup.mainGroup === data.service_name)
    .flatMap((serviceGroup) => serviceGroup.subGroups[0]?.documents || []);

  const requiredFiles = `
  ${groupLevel.map((doc) => `- ${doc}`).join("\n  ")}
`;
  // let groupLevel = serviceDataForBH.map((serviceGroup) => {
  //   if (serviceGroup.mainGroup == data.service_name) {
  //     console.log(serviceGroup);
  //     return serviceGroup.subGroups[0].documents;
  //   }
  // });
  // const requiredFiles = `${groupLevel.map((doc) => `- ${doc}`).join("\n  ")}`;
  // console.log("the reqfile: ", requiredFiles);

  // Add company logo
  doc.addImage("./icon.png", "PNG", 40, 30, 20, 20); // (x, y, width, height)

  // Add company name
  doc.setFont("Helvetica", "bold");
  doc.setFontSize(22);
  doc.text("Qetero", 105, 40, { align: "center" });

  // Add current date and receipt number
  const currentDate = new Date().toLocaleDateString(); // Get the current date
  doc.setFontSize(12);
  doc.text(`Date: ${currentDate}`, 150, 70);
  doc.text(`Receipt No: ${data.cnr}`, 150, 77);
  doc.setFont("Helvetica", "normal");

  // Add booking details
  doc.setFontSize(14);
  doc.setFont("Helvetica", "semibold");
  doc.text(`Booked By: ${data.firstname} ${data.lastname}`, 20, 100);
  doc.text(`CNR: ${data.cnr}`, 20, 110);
  doc.text(`Service: ${data.service_name}`, 20, 120);
  doc.text(
    `Appointment Time: ${formatAppointmentTime(data.appointment_time)}`,
    20,
    130
  );

  // Draw a border around the ticket
  doc.setLineWidth(0.5);
  doc.rect(7, 7, 196, 283);

  doc.setFontSize(12);
  doc.setFont("Helvetica", "bold");
  doc.text(`Documents required For ${data.service_name}`, 20, 150);
  const splitReqFile = doc.splitTextToSize(requiredFiles, 140);
  doc.setFont("Helvetica", "semibold");
  doc.text(splitReqFile, 20, 160);
  doc.text(`For more information visit: qetero.com/required`, 25, 190)

  // Add appointment policy consent section
  doc.setFontSize(12);
  doc.setFont("Helvetica", "bold");
  doc.text("Appointment Policy Consent", 20, 210);

  doc.setFont("Helvetica", "semibold");
  const policyText = `
    - Appointments are non-refundable.
    - Appointments cannot be transferred to another person.
    - The appointment is valid only for the specific service you have booked.
    - You may only book one service per appointment.
    - A valid ID must be presented at the time of the appointment.
    - You must arrive on time for your appointment.
    - If you are late, we reserve the right not to serve you.
  `;

  // Split policy text into lines to fit the PDF width
  const splitPolicyText = doc.splitTextToSize(policyText, 140);
  doc.text(splitPolicyText, 20, 220); // x=20, y=170 for proper alignment and spacing

  // QRCodeCanvas reference (assuming you render it temporarily in the DOM)
  const qrCodeCanvas = document.getElementById("qrcode-canvas");

  if (qrCodeCanvas) {
    // Convert QR code canvas to base64 image
    const qrImage = qrCodeCanvas.toDataURL("image/png");

    // Add QR code to the PDF after policy section, styled with padding
    doc.addImage(qrImage, "PNG", 150, 90, 40, 40); // (x=85, y=230 for centering QR code)

    // Save the PDF
    doc.save("appointment.pdf");
  }
};

const AppointmentDetailModal = ({ isOpen, onClose, data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleClose = () => {
    navigate("/bookingHistory");
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
        <img
          src="./qetero_logo_text_3.svg"
          alt="Qetero"
          className="w-32 h-12 mb-2 self-center"
        />

        <div className="border-t border-b py-4 mb-4">
          <div className="text-sm">
            <p>
              <strong>{t("booked_by")}:</strong> {data.firstname}{" "}
              {data.lastname}
            </p>
            <p>
              <strong>CNR:</strong> {data.cnr}
            </p>
            <p>
              <strong>{t("service")}:</strong> {data.service_name}
            </p>
            <p>
              <strong>{t("branchName")}:</strong> {data.branch_name}
            </p>
            <p>
              <strong>{t("appointmentTime")}:</strong>{" "}
              {formatAppointmentTime(data.appointment_time)}
            </p>
          </div>
        </div>
        <div className="flex justify-center mb-4">
          <QRCodeCanvas id="qrcode-canvas" value={data.cnr} size={150} />
        </div>
        <p className="text-sm text-center">{t("scanQRCode")}</p>
        <div className="flex justify-evenly mt-4">
          <button
            className="bg-indigo-600 w-1/2 text-white font-bold py-2 px-4 rounded m-2"
            onClick={() => generatePdf(data)}
          >
            Download PDF
          </button>
          <button
            className="bg-indigo-600 w-1/2 text-white font-bold py-2 px-4 rounded m-2"
            onClick={handleClose}
          >
            {t("close")}
          </button>
        </div>
      </div>
    </div>
  );
};

const BookingHistory = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTerm, setsearchTerm] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { token, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSearch = () => {
    setsearchTerm(searchQuery);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: [
      "appointments",
      {
        userId: user?.user?.id,
        page: currentPage,
        limit: pageSize,
        searchTerm: searchTerm,
        token: token,
      },
    ],
    queryFn: fetchAppointments,
    keepPreviousData: true,
  });

  const totalItems = data?.totalItems || 0;
  const bookingHistory = data?.data || [];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    // Subtract 6 hours
    const modifiedDate = new Date(
      new Date(date).getTime() - 6 * 60 * 60 * 1000
    );
    return modifiedDate.toLocaleString("en-US", options);
  };

  const handleClickonAppointment = (appointment) => {
    setSelectedAppointment(appointment);
    setModalOpen(true);
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {isLoading ? (
            <StatusBoxSkeleton />
          ) : (
            <StatusBox
              fromColor={"from-primary"}
              toColor={"to-yellow-400"}
              icon={FaListAlt}
              title={t("total")}
              stat={totalItems}
            />
          )}
          {isLoading ? (
            <StatusBoxSkeleton />
          ) : (
            <StatusBox
              fromColor={"from-primary"}
              toColor={"to-green-500"}
              icon={FaCheckCircle}
              title={t("upcoming")}
              stat={data?.statusCounts?.confirmed || 0} // Adjust according to your data structure
            />
          )}
          {isLoading ? (
            <StatusBoxSkeleton />
          ) : (
            <StatusBox
              fromColor={"from-primary"}
              toColor={"to-green-200"}
              icon={FaClock}
              title={t("completed")}
              stat={data?.statusCounts?.["checked out"] || 0} // Adjust according to your data structure
            />
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-evenly items-start pr-4 md:pr-32 mt-5 space-y-4 md:space-y-0">
        <div className="flex flex-row md:flex-row items-center sm:flex-row mb-4 w-full md:w-1/2 max-w-full space-y-2 sm:space-y-0 sm:space-x-2">
          <input
            type="text"
            placeholder={t("searchPlaceHolder")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-md flex-grow w-full"
          />
          <button
            onClick={handleSearch}
            className="px-4 py-2 bg-blue-500 text-white rounded-md sm:w-auto "
          >
            {t("search")}
          </button>
        </div>
      </div>

      <div className="flex flex-wrap justify-around">
        {isLoading ? (
          <LoadingSpinner />
        ) : bookingHistory.length === 0 ? (
          <div className="text-center">{t("noBookingFound")}</div>
        ) : (
          bookingHistory.map((appointment) => {
            if (appointment.status == "booked") {
              return;
            } else {
              return (
                <div
                  key={appointment.id}
                  className="border border-gray-200 rounded-lg p-4 m-2 w-72 shadow-lg"
                >
                  <div className="flex justify-between mb-2">
                    <div>
                      <h3 className="font-bold">
                        {t(appointment.service_name)}
                      </h3>
                      <p className="text-sm text-gray-600">
                        CNR: {appointment.cnr}
                      </p>
                    </div>
                    <button
                      onClick={() => handleClickonAppointment(appointment)}
                      className="text-blue-500 hover:underline"
                    >
                      {t("view")}
                    </button>
                  </div>
                  <p className="text-sm text-gray-600">
                    {t("appointmentTime")}:{" "}
                    {formatDate(appointment.appointment_time)}
                  </p>
                  <div className="flex justify-between mb-2">
                    <p
                      className={`font-semibold ${appointment.status === "confirmed"
                          ? "text-green-500"
                          : appointment.status === "booked"
                            ? "text-blue-500"
                            : appointment.status === "checkedIn"
                              ? "text-yellow-500"
                              : appointment.status === "checkedOut"
                                ? "text-gray-500"
                                : "text-red-500" // default color for any other status
                        }`}
                    >
                      {t("status")}: {appointment.status}
                    </p>
                    <button
                      onClick={() => {
                        console.log(appointment);
                        const appointmentTime = new Date(appointment.appointment_time);
                        const currentTime = new Date();

                        // Calculate the time difference in milliseconds
                        const timeDifference = appointmentTime.getTime() - currentTime.getTime();

                        // Convert milliseconds to hours
                        const hoursDifference = timeDifference / (1000 * 60 * 60);

                        // navigate("/Reschedule", {
                        //   state: { appointment_id: appointment.appointment_id, branch_service_id: appointment.branch_service_id, branch_id: appointment.branch_id }
                        // });
                        if(hoursDifference >= 3){
                          navigate("/Reschedule", {
                            state: { appointment_id: appointment.appointment_id, branch_service_id: appointment.branch_service_id, branch_id: appointment.branch_id }
                          });
                        }  
                        else {
                            notification.error({
                              message: "Error",
                              description: "Rescheduling is only allowed if at least 3 hours remain.",
                              duration: 2, 
                            });
                        }
                                            
                      }}
                      className="text-blue-500 hover:underline"
                    >
                      {t("reschedule")}
                    </button>
                  </div>
                </div>
              );
            }
          })
        )}
      </div>
      {/* Pagination */}
      <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6 mt-5">
        <div className="flex-1 flex justify-between sm:hidden">
          <a
            href="#"
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="#"
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing{" "}
              <span className="font-medium">
                {(currentPage - 1) * pageSize + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(currentPage * pageSize, totalItems)}
              </span>{" "}
              of <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <a
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                {/* Previous Page Icon */}
              </a>
              {/* Pagination Number Buttons */}
              {Array.from({
                length: Math.ceil(totalItems / pageSize),
              }).map((_, index) => (
                <a
                  key={index}
                  href="#"
                  className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === index + 1
                      ? "text-blue-600"
                      : "text-gray-700 hover:bg-gray-50"
                    }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </a>
              ))}
              <a
                href="#"
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(totalItems / pageSize)}
              >
                <span className="sr-only">Next</span>
                {/* Next Page Icon */}
              </a>
            </nav>
          </div>
          {/* Page Size Selector */}
          <div className="relative inline-block text-left">
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              {[10, 20, 30].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
          </div>
        </div>

        <AppointmentDetailModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          data={selectedAppointment}
        />
      </div>
    </div>
  );
};

export default BookingHistory;
