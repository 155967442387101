import React, { useRef, useState } from 'react';
import i18n from '../../i18n';
import { convertToAmPm, convertToEthiopianDate, convertToEthiopianLocalTime, formatTime } from '../../utils/convertTime';
import { useMutation } from '@tanstack/react-query'; // Import useMutation
import axios from 'axios';

const ConfirmationModal = ({
    selectedBranchName,
    selectedServiceName,
    isLoading,
    selectedDate,
    selectedTimeSlot,
    totalFee = "50 ETB",
    onClose,
    documentCount,
    setDocumentCount,
    onConfirm,
    firstname,
    lastname
  }) => {
    const [hasMoreDocuments, setHasMoreDocuments] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

  


    const handleDocumentCountChange = (e) => {
      const value = parseInt(e.target.value, 10);
  
      if (value > 3) {
        setErrorMessage(i18n.t("For 1 appointment, you can have up to 3 documents."));
        setDocumentCount(3);
      } else {
        setErrorMessage("");
        setDocumentCount(value);
      }
    };
  



    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-2 z-50">
        <div className="bg-white border border-gray-400 p-6 md:p-12 rounded-lg shadow-2xl w-full max-w-md md:max-w-lg transform transition-all duration-300">
          <h2 className="text-xl md:text-2xl font-bold text-gray-800 mb-8 text-start">{i18n.t('confirm_booking')}</h2>
          <div>
            </div>
          <div className="text-sm md:text-lg text-gray-700">
      {  firstname   && (<div className="flex justify-between gap-4 mb-4">
              <span className="font-normal">{i18n.t('apointment_for')}:</span>
              <span className="font-semibold">{firstname} {lastname}</span>
            </div>)}


            <div className="flex justify-between gap-4 mb-4">
              <span className="font-normal">{i18n.t('branch')}:</span>
              <span className="font-semibold">{i18n.t(selectedBranchName)}</span>
            </div>
            <div className="flex justify-between gap-4 pb-4 mb-4 border-b border-gray-300">
              <span className="font-normal">{i18n.t('service')}:</span>
              <span className="font-semibold">{i18n.t(selectedServiceName)}</span>
            </div>
  
            <div className="flex justify-between gap-4 mb-4">
              <span className="font-normal">{i18n.t('bookingDate')}:</span>
              <div className="flex flex-col justify-end items-end">
                <span className="font-semibold text-xs md:text-sm">{convertToEthiopianDate(selectedDate)} </span>
                <span className="font-semibold  text-xs md:text-sm">{formatTime(selectedDate)} </span>
              </div>
            </div>
            <div className="flex justify-between gap-4 mb-4">
              <span className="font-normal">{i18n.t('bookingTime')}</span>
              <div className="flex flex-col justify-end items-end">
                <span className="font-semibold text-xs md:text-sm">{convertToEthiopianLocalTime(selectedTimeSlot?.time_from)}{`( ${convertToAmPm(selectedTimeSlot?.time_from)} )`}</span>
              </div>
            </div>
          </div>
  
          <div className="flex justify-around items-center space-x-4 mt-4">
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-700 text-base md:text-lg py-2 px-5 rounded-lg shadow hover:bg-gray-400 focus:ring-2 focus:ring-gray-300 focus:outline-none transition duration-300"
            >
              {i18n.t('cancel')}
            </button>
            <button
              onClick={onConfirm}
              className="flex items-center bg-primary font-bold border border-primary text-white text-sm md:text-lg py-2 px-5 rounded-lg shadow hover:bg-black-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transition duration-300"
            >
              {isLoading ? i18n.t('loading') : i18n.t('confirm')}
            </button>
          </div>
        </div>
      </div>
    );
  };


export default ConfirmationModal