import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import { useQuery } from '@tanstack/react-query';
import BranchSelection from './BranchSelection';
import DateSelection from './DateSelection';
import ServiceSelection from './ServiceSelection';
import TimeSlotSelection from './TimeSlotSelection';
import { notification } from "antd";

import { jwtDecode } from 'jwt-decode';
import ConfirmationModal from './ConfrmationModal';
import BookingForSelection from './BookingForSelection';
import Review from './Review';


const fetchLastCheckedoutAppointment = async (token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/reviews/last_appointment`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching last checked-out appointment:", error);
    return null; // Or throw the error, depending on your error handling strategy
  }
};


const Booking = () => {
  const fixedAmount = "50";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState();
  const [selectedServiceOriginal, setSelectedServiceOriginal] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [documentCount, setDocumentCount] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const { user, token,setToken } = useContext(AuthContext);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [mobile, setMobile] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [bookingForSelf, setBookingForSelf] = useState(true);
  const [otherPersonMobile, setOtherPersonMobile] = useState('');
  const announcementMessage = "DARS branches will soon offer an online appointment booking system to streamline visits, reduce wait times, and enhance your experience. Stay tuned for updates!";
  
  const news = "Exciting news!";
  const location = useLocation();
  const { appointment_id, branch_service_id, branch_id } = location.state || {};
  useEffect(() => {
    console.log("AppointmentId: ", appointment_id);
    console.log("BranchServiceId: ", branch_service_id);
    console.log("BranchId: ", branch_id);
    
  },[appointment_id, branch_service_id])

  const stepTitles = ['selectBranch', 'selectService', 'selectDate', 'selectTimeSlot','bookingFor'];

  const { data: lastCheckedOutAppointment } = useQuery({
    queryKey: ["lastCheckedOutAppointment", token],
    queryFn: () => fetchLastCheckedoutAppointment(token),
    enabled: !!token, // Only run if token exists
  });


  console.log("appointment", lastCheckedOutAppointment)

  const handleCloseModal = () => {
    setShowModal(false);
    setIsLoading(false);
    navigate('/bookingHistory');
  };


  const nextStep = () => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
    if (step === 1) {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp < currentTime) {
            localStorage.removeItem('token');
            setToken(null);
            navigate('/Login');
            return;
          } else {
            setStep(2);
            return;
          }
        } catch (error) {
          console.error("Invalid token:", error);
          navigate('/Login');
          return;
        }
      } else {
        // No token, redirect to login
        navigate('/Login');
      }
    }
  
    // Proceed with the remaining steps if the token is valid
    if (step === 2) setStep(3);
    if (step === 3) setStep(4);
    if (step === 4) setShowModal(true);
    // if (step === 5) setStep(6);
    //if (step === 5) setShowModal(true);
  };

  const initiatePayment = async (appointmentId) => {
    try {
      setIsLoading(true);
      //If C2B
      if (window.consumerapp === undefined || window.consumerapp === null) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/payments`, {
          appointment_id: appointmentId,
          type: "service Fee",
          amount: fixedAmount.toString(),
          title: "serviceFee_" + fixedAmount,
          paymentType: "C2B"
        });

        const paymentUrl = response.data.url;
        if (paymentUrl) {
          window.location.href = paymentUrl.trim();
        }


      }
      else { //If Telebirr Superapp 
        window.handleinitDataCallback = function () {
          window.location.href = window.location.origin;
        };
        if (!fixedAmount) {
          return;
        }
        setIsLoading(true);
        window
          .fetch(`${process.env.REACT_APP_API_URL}/v1/payments`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              appointment_id: appointmentId,
              type: "service Fee",
              amount: fixedAmount.toString(),
              title: "serviceFee_" + fixedAmount,
              paymentType: "InApp"
            }),
          })
          .then((res) => {
            res
              .text()
              .then((rawRequest) => {
                //   console.log(rawRequest.trim());
                let obj = JSON.stringify({
                  functionName: "js_fun_start_pay",
                  params: {
                    rawRequest: rawRequest.trim(),
                    functionCallBackName: "handleinitDataCallback",
                  },
                });

                if (typeof rawRequest === undefined || rawRequest === null) return;
                if (window.consumerapp === undefined || window.consumerapp === null) {
                  // console.log("This is not opened in app!");
                  return;
                }
                window.consumerapp.evaluate(obj);
              })
              .catch((error) => {
                console.log("error occur", error);
              })
              .finally(() => { });
          })
          .finally(() => {
            //loading.hide();
            setIsLoading(true);
          });
      }

    } catch (error) {
      console.error("Error initiating payment:", error.message);
    } finally {
      setIsLoading(true);
    }
  };

  const handleBookingForSelect = (forSelf, mobile) => {
    setBookingForSelf(forSelf);
    setOtherPersonMobile(mobile);

};
 

const addAppointmentAndPay = async () => {
    setIsLoading(true);
    const [hours, minutes] = selectedTimeSlot.time_from.split(':');
    const date = new Date(selectedDate);
    date.setHours(hours);
    date.setMinutes(minutes);

    const appointmentData = {
      appointmentId: appointment_id,
      branch_id: selectedBranch,
      branch_service_id: selectedService,
      appointment_duration_id: selectedTimeSlot.duration_id,
      appointment_date: date.toISOString().split("T")[0],
      created_by: user?.id,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/appointments/rescheduleAppointmentSelf`,
        appointmentData
      );

      if (response.data?.status === "success") {
        notification.success({
          message: "Success",
          description: "Reschedule successful.",
          duration: 2, 
        });
        navigate('/bookingHistory');
      }
    } catch (err) {
      console.error("Error creating appointment:", err);
    } finally {
      setIsLoading(true);
      setShowModal(true);
    }
  };

  return (
    <div className="flex justify-center items-center flex-col">
      <div className="flex pt-4 bg-gray-100 justify-center align-middle self-center">
        {stepTitles.map((title, index) => (
          <div key={index} className="flex items-center mr-4 mb-2">
            <div className={`w-12 h-2 rounded-r-md flex items-center justify-center ${step >= index + 1 ? "bg-pink-600" : "bg-gray-700"}`}></div>
          </div>
        ))}
      </div>
      {/* {
        step === 1 && (
          <SelectOrganization
            setOrganization={setSelectedOrganization}
            nextStep={nextStep}
          />
        )
      } */}
      {step === 1 && (
        <BranchSelection
          selectedOrganization={selectedOrganization}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          setSelectedBranchName={setSelectedBranchName}
          nextStep={nextStep}
          backStep={() => setStep(1)}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      {step === 2 && (
        <ServiceSelection
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          selectedServiceOriginal={selectedServiceOriginal}
          setSelectedService={setSelectedService}
          setSelectedServiceOriginal={setSelectedServiceOriginal}
          setSelectedServiceName={setSelectedServiceName}
          selectedAddress={selectedAddress}
          nextStep={nextStep}
          backStep={() => setStep(1)}
          selectedBranchName={selectedBranchName}
        />
      )}
      {step === 3 && (
        <DateSelection
          selectedDate={selectedDate}
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          setSelectedDate={setSelectedDate}
          selectedServiceName={selectedServiceName}
          selectedBranchName={selectedBranchName}
          backStep={() => setStep(2)}
          nextStep={nextStep}
        />
      )}
      {step === 4 && (
        <TimeSlotSelection
          selectedBranch={selectedBranch}
          selectedService={selectedService}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          setSelectedTimeSlot={setSelectedTimeSlot}
          selectedServiceName={selectedServiceName}
          selectedBranchName={selectedBranchName}
          backStep={() => setStep(3)}
          nextStep={nextStep}
        />
      )}
    {step === 5 && (
        <BookingForSelection
            onSelect={handleBookingForSelect}
            backStep={() => setStep(4)}
            firstname={firstname}
            lastname={lastname}
            mobile={mobile}
            nextStep={nextStep}
            setFirstname={setFirstname}
            setLastname={setLastname}
            setMobile={setMobile}
            bookingForSelf={bookingForSelf}
            setBookingForSelf={setBookingForSelf}
          />
    )}
      {showModal && (
        <ConfirmationModal
          selectedBranchName={selectedBranchName}
          selectedServiceName={selectedServiceName}
          firstname={firstname}
          lastname={lastname}
          selectedDate={selectedDate}
          selectedTimeSlot={selectedTimeSlot}
          onClose={() => setShowModal(false)}
          onConfirm={addAppointmentAndPay}
          isLoading={isLoading}
          documentCount={documentCount}
          setDocumentCount={setDocumentCount}
        />
      )}
     {lastCheckedOutAppointment?.lastAppointment && <Review lastCheckedOutAppointment={lastCheckedOutAppointment} /> } 
    </div>
  );
};

export default Booking;
