import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/QeteroHeader";
import "../../index.css";
import SimpleFooter from "../Footer/SimpleFooter";
import MultiForm from '../Reschedule';
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/authContext";
function Reschedule() {
  const { t } = useTranslation(); // Initialize useTranslation hook for i18n
  const {user, setToken, setUser} = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    date: null,
    time: null,
    location: "",
    service: "",
  });

  

  return (
    <div>
      <div className="p-2">
        <Header />
        <div className="flex flex-row  flex-1 align-middle justify-around px-1 py-1 from-primary to-blue-500 bg-gradient-to-r rounded-xl text-stone-900 ">
          <div className="flex flex-col ml-2 justify-start text-2xl w-1/2 p-2 md:text-3xl font-semibold text-white">
            <div className="font-bold  text-xl md:text-3xl mb-2 text-white">{t("selam")} <span>{user?.firstname }</span> </div>
            <p  className=" font-normal   text-xs md:text-lg text-gray-300 ">{(window.consumerapp === undefined || window.consumerapp === null) ? t('Welcome_to_Documents_Authentication_and_Registration_Service_(DARS)_at_Qetero.com') : t('welcome_to_qetero')}</p>
          </div>
          <div className="flex justify-end w-1/2">
            <img src="/Dars-Logo.png" alt="Qetero" className="w-28 h-28 md:w-32 md:h-32 self-center z-0 contain-content" />
         </div>
        </div>

      </div>
      <MultiForm />
      <SimpleFooter />
    </div>
  );
}

export default Reschedule;